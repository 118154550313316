import React, { Component } from 'react'

import InputField from './partials/InputField'
import TextField from './partials/TextField'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      emailValid: true,
      nameValid: true,
      messageValid: true,
      formValid: false,
      isSending: false,
      isSend: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.validateField = this.validateField.bind(this)
  }

  handleChange(e) {
    let change = {}
    let name = e.target.name
    let value = e.target.value
    change[name] = value
    this.setState(
      {
        [name]: value,
      },
      () => this.validateField(name, value),
    )
  }

  clearForm() {
    this.setState({
      name: '',
      email: '',
      phone: '',
      message: '',
      notes: '',
    })
  }

  validateField(fieldName, value) {
    let emailValid = this.state.emailValid
    let nameValid = this.state.nameValid
    let messageValid = this.state.messageValid

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        break
      case 'name':
        nameValid = value.length >= 1
        break
      case 'message':
        messageValid = value.length >= 1
        break
      default:
        break
    }

    this.setState({
      emailValid: emailValid,
      nameValid: nameValid,
      messageValid: messageValid,
    })
  }

  validateFields() {
    this.setState(
      {
        emailValid: this.state.email.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        ),
        nameValid: this.state.name.length >= 1,
        messageValid: this.state.message.length >= 1,
      },
      function() {
        this.validateForm()
      },
    )
  }

  validateForm() {
    this.setState(
      {
        formValid:
          this.state.emailValid &&
          this.state.nameValid &&
          this.state.messageValid,
      },
      function() {
        this.sendForm()
      },
    )
  }

  handleFormSubmit(e) {
    e.preventDefault()
    this.validateFields()
  }

  sendForm() {
    if (!this.state.isSending && this.state.formValid === true) {
      let contactTitle = this.props.formTitle
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': contactTitle, ...this.state }),
      })
        .then(() => {
          this.setState({
            isSending: false,
            isSend: true,
          })
          this.clearForm()
        })
        .catch((error) => alert(error))
    }
  }

  render() {
    const contactTitle = this.props.formTitle
    const statusClass =
      this.state.isSending || this.state.isSend
        ? 'form-container__status form-container__status--active'
        : 'form-container__status'
    const formWrapperClass =
      this.state.isSending || this.state.isSend
        ? 'form-within form-within--active'
        : 'form-within'
    const sendingStatus = !this.state.isSend ? (
      <div className='loading-dots loading-dots--blue'>
        <div className='loading-dots--dot'></div>
        <div className='loading-dots--dot'></div>
        <div className='loading-dots--dot'></div>
      </div>
    ) : (
      <h2 className='title-3'>Thank you for reaching out!</h2>
    )

    return (
      <form
        onSubmit={this.handleFormSubmit}
        className='form-container'
        name={contactTitle}
        data-netlify='true'>
        <div className={statusClass}>{sendingStatus}</div>
        <div className={formWrapperClass}>
          <div className='field-grouped'>
            <InputField
              type='text'
              name='name'
              placeholder='Your name'
              onChange={this.handleChange}
              error={this.state.nameValid}
              errorMessage='This field cannot be empty'
            />
          </div>

          <div className='field-grouped'>
            <InputField
              type='email'
              name='email'
              placeholder='Email'
              onChange={this.handleChange}
              error={this.state.emailValid}
              errorMessage='Please provide a correct email address'
            />
          </div>

          <div className='field-grouped'>
            <InputField
              type='tel'
              name='phone'
              error={true}
              placeholder='Phone number'
              onChange={this.handleChange}
            />
          </div>

          <TextField
            name='message'
            placeholder="What's new, pussycat?"
            onChange={this.handleChange}
            error={this.state.messageValid}
            errorMessage='How can we help?'
          />

          <div className='control'>
            <button
              className='btn btn-white btn-submit'
              disabled={this.state.isSending}>
              Send
            </button>
          </div>
        </div>
      </form>
    )
  }
}
