import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import SEO from '@/helpers/SEOHelper.js'

import Layout from '@/components/Layout'
import ContactForm from '@/components/Forms/Contact'
import Map from '@/components/Map'

export const ContactPageTemplate = ({ hero, bg, building }) => {
  return (
    <>
      <section className='relative-section overscroll-x-hidden pt-64'>
        {bg ? (
          <GatsbyImage
            image={getImage(bg.childImageSharp)}
            className='background img-grey op-5'
          />
        ) : (
          ''
        )}
        <section className='hero hero--small'>
          <div className='hero__wrapper'>
            <div className='container'>
              <div className='cols'>
                <div className='col-50' data-aos='fade-right'>
                  <h2 className='title-6 text-accent'>{hero.subheading}</h2>
                  <h1 className='hero__title'>{hero.heading}</h1>
                  <ReactMarkdown
                    className='hero__content'
                    children={hero.description}
                  />
                </div>
                <div className='col-50' data-aos='fade-left'>
                  <ContactForm formTitle='Contact Form' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container'>
            <div className='cols cols--fill cols--15'>
              <div className='col-50' data-aos='fade-right'>
                <GatsbyImage
                  image={getImage(building)}
                  className='contact-building'
                  alt='Building - dionysus headquaters'
                />
              </div>
              <div className='col-50' data-aos='fade-left'>
                <Map
                  googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD-fyLY5DapIlPayPFoEQB2xteEj2aFyUk'
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const seo = frontmatter.seo

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo?.image?.publicURL)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <ContactPageTemplate
        hero={frontmatter.hero}
        bg={frontmatter.image}
        building={frontmatter.building}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              quality: 45
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { grayscale: true }
            )
          }
        }
        building {
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 45
              formats: [AUTO, WEBP, AVIF]
              placeholder: DOMINANT_COLOR
            )
          }
        }
        hero {
          subheading
          heading
          description
        }
        seo {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`
